@import url("https://fonts.googleapis.com/css2?family=Nunito:wght@200;300&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Nunito", sans-serif;
}

body {
  background-color: #0b1629;
  color: #fff;
}

html {
  scroll-behavior: smooth;
}

#home,
#contact {
  overflow: hidden;
}
